import '../sass/app.scss'

$(function () {
    AOS.init();

    $('.scrollBtn').on('click',function (e) {
        e.preventDefault();
        var id = $(this).attr('id');
        $('html, body').animate({
            scrollTop: $('div#'+id).offset().top - 10
        }, 0);
    });

    $('.dropdown-toggle').click(function () {
        $(this).find('.arrow-up').toggle(0);
        $(this).parent().find('.dropdown-menu').toggle();
    });

    $('.count').each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
    $('#calbackForm').submit(function(){
      $.post('/callback/save', $(this).serialize(), function(res){
        if(res.message !== null){
    /* alert('Teşekkürler Arama isteğiniz elimize ulaştı. En kısa sürede size dönüş yapacağız.');*/
        }else{
          alert(res.message);
        }
      });
      return false;
    });
    /*menüdeki resimlerin değiştirilmesi*/
    const base_url = window.location.origin;
    $('.sub-menu-item').on('mouseenter', function(e){
        let imgUrl = $(this).attr('imgUrl');
        let url = base_url+imgUrl;
        $('.change-img').attr('src',url);
    });
    $('.sub-menu-item').on('mouseleave', function(e){
        let imgUrl = $('.change-img').attr('baseUrl');
        let url = base_url+imgUrl;
        $('.change-img').attr('src',url);
    })

    var swiper3 = new Swiper(".referance-swiper", {
        slidesPerView: 6,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
            clickable:true,
        },
        navigation: {
            nextEl: ".referance .swiper-button-next",
            prevEl: ".referance .swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 50,
            },
        },
    });

    $('.referance-swiper').on('mouseenter', function(e){
        swiper3.autoplay.stop();
    })
    $('.referance-swiper').on('mouseleave', function(e){
        swiper3.autoplay.start();
    })

    var swiper = new Swiper(".mySwiper", {
        loop:true,
        speed: 600,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        on: {
            slideChangeTransitionStart: function () {
            },
            slideChangeTransitionEnd: function () {
                AOS.init();
            },
        }
    });
    var swiper2 = new Swiper(".customer-comment-swiper", {
        slidesPerView: 3,
        loop: true,
        spaceBetween: 30,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
        },
    });

    $('.customer-comment-swiper .swiper-wrapper .swiper-slide').on('mouseover', function (){
        swiper2.autoplay.stop();
    });
    $('.customer-comment-swiper .swiper-wrapper .swiper-slide').on('mouseout', function (){
        swiper2.autoplay.start();
    });
    $('.swiper-slide').on('mouseover', function() {
        swiper.autoplay.stop();
    });

    $('.swiper-slide').on('mouseout', function() {
        swiper.autoplay.start();
    });

});
